import React from 'react';

import { About, Footer, Header, Experiences, Education, Skills, Testimonial, Portfolios } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
    <About />
    <Skills />
    <Portfolios />
    <Experiences />
    <Education/>
    <Testimonial />
    <Footer />
  </div>
);

export default App;
