import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [error, setError] = useState('');

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendMessage = () => {
    if (!name || !email || !message) {
      setError("Name, Email, and Message must be filled in");
      return;
    }

    const whatsappNumber = "+6282190166915";
    const formattedPhoneNumber = whatsappNumber.replace(/\D/g, ""); // Remove non-digit characters

    // Combine name, email, and message values into the message
    const fullMessage = `Name: ${name}%0AEmail: ${email}%0A${encodeURIComponent(message)}`;

    const whatsappLink = `https://wa.me/${formattedPhoneNumber}?text=${fullMessage}`;
    window.open(whatsappLink, "_blank");

    setIsFormSubmitted(true);
  };

  return (
    <>
      <h2 className="head-text">Let's <span>Connect</span> & Talk</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:maxinegrey438@gmail.com" className="p-text">maxinegrey438@gmail.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+6282190166915" className="p-text">+6282190166915</a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="name" value={name} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          {error && <p className="error-text">{error}</p>}
          <button type="button" className="p-text" onClick={handleSendMessage}>Send to WhatsApp</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in touch!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__primarybg',
);
