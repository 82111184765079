import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://www.linkedin.com/in/argya-maghfirridho-7a7714163" target="_blank">
        <FaLinkedinIn />
      </a>
    </div>
    <div>
      <a href="https://instagram.com/argya_maxphiride?igshid=bG51N3Uxdnc4OXdz" target="_blank">
        <BsInstagram />
      </a>
    </div>
    <div>
      <a href="https://github.com/argyax" target="_blank">
        <AiFillGithub />
      </a>
    </div>
  </div>
);

export default SocialMedia;