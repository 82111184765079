import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {Tooltip as ReactTooltip} from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Experiences.scss';

const Experiences = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });
  }, []);

  return (
    <>
     <h2 className="head-text">Experiences</h2>
      <div className="app__skills-container">
        <div className="app__resume-exp">
          <div class="resume-card">
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
            <span>Abstractions Into Realities</span>
            <p class="info">
              Every challenge is a puzzle waiting to be solved, and every twist is an opportunity to innovate. 
              That’s where I come in. From the moment we start, I’m there, knee-deep in, working, 
              to turn ideas into reality. I’m committed to being your go-to problem solver, ready to bring a fresh perspective 
              to every stage of your journey. Let’s make this journey not just successful but unforgettable!
            </p>
            <a href="/CV_Argya_Software Engineering.pdf" download="Argya_Resume.pdf" type='button'>
              Download My CV
            </a>
          </div>
        </div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}>
              <div className="app__skills-exp-year">
                <p className="year-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works && experience.works.map((work) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="role-text">{work.name}</h4>
                      <p className="company-text">{work.company}</p>
                      <p className="work-desc">{work.desc}</p>
                      <div className='hashtag-list'>
                        {work.tags && work.tags.map((tag, index) => (
                          <button key={index} className='hashtag'>{tag}</button>
                        ))}
                      </div>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experiences, 'app__skills'),
  'experiences',
  'app__primarybg',
);
