import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';
import { FaCode } from "react-icons/fa";
import { GiElectric, GiSoundWaves } from "react-icons/gi";
import { SiPolymerproject } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { GoProjectTemplate } from "react-icons/go";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      console.log('Abouts Data:', data);
      setAbouts(data);
    });
  }, []);

  return (
    <>
    <h2 className="head-text">Crafting <span>Seamless Experiences</span> <br />Engineering <span>Future,</span> Today</h2>
    <div className='profile_container'>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title ? about.title + index : index}
          >
            <img src={about.imgUrl && urlFor(about.imgUrl)} alt={about.title} />
            <h3 className="my-name" style={{ marginTop: 20 }}>{about.title}</h3>
            <p className="about-text" style={{ marginTop: 10 }}>{about.description}</p>
            <p className="about-text" style={{ marginTop: 10 }}>{about.description2}</p>
            <p className="about-text" style={{ marginTop: 10 }}>{about.description3}</p>
          </motion.div>
        ))}
      </div>
      <div className="interests">
        <h3 className="card-text" style={{ marginTop: 20 }}>These Are <span>My</span> Core <span>Competencies</span></h3>
        <div className="skills">
          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <FaCode className="icon"/>
              <br />      
                <h4 className='card-title'>Software Engineering</h4>
              <br />
            </div>
          </div>

          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <GiElectric className="icon"/>
              <br />      
                <h4 className='card-title'>Electronics and Embedded System</h4>
              <br />
            </div>
          </div>

          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <GoProjectTemplate className='icon' fill='currentColor'/>
              <br />      
              <h4 className='card-title'>Project Management</h4>
              <br />
            </div>
          </div>

          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <SiPolymerproject className="icon"/>
              <br />      
                <h4 className='card-title'>Engineering Design</h4>
              <br />
            </div>
          </div>

          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <GiSoundWaves className="icon"/>
              <br />      
                <h4 className='card-title'>Acoustic Instrumentation</h4>
              <br />
            </div>
          </div>

          <div className="e-card playing">
            <div className="image"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
              <CgWebsite className="icon"/>
              <br />      
                <h4 className='card-title'>UI/UX</h4>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
