import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
// import { Tooltip as ReactTooltip } from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      console.log('Skills Data:', data); // Log works data
      setSkills(data);
      setFilterSkills(data);
    }).catch((error) => {
      console.error('Error fetching skills:', error);
    });
  }, []);

  const handleSkillsFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);
  
    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
  
      if (item === 'All') {
        setFilterSkills(skills);
      } else {
        setFilterSkills(
          skills.filter((skill) => skill.tags && skill.tags.includes(item))
        );
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">Technical <span>Skills</span></h2>
      <div className="app__skills-filter">
        {['Programming Languages', 'Frameworks', 'Tools', 'Design', 'All'].map((item) => (
          <div
            key={item}
            onClick={() => handleSkillsFilter(item)}
            className={`app__skills-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="app__skills-container">
        <motion.div className="app__skills-list" animate={animateCard}>
          {filterSkills.map((skill) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={skill.icon && urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__primarybg',
);
