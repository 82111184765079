import React from 'react';
//import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
//import { images } from '../../constants';
//import {hero} from '../../constants';
import './Header.scss';
//import Generating from '../assets';
//import Notification from '../assets';

const Header = () => (
  <section className="app__header">
    <div class="container">
      <div class="gradient-border">
        <div class="creative-toolbox-container"> 
          <h1 className='hero-text'>
            Hey There! My <span>Name</span>&nbsp;is<br/>
            <span>
              Argya A. Maghfirridho
            </span>
          </h1>
          <p className='hero-desc'>A science-driven innovator with a passion for creativity and growth.</p> 
        </div>
          <a href="#about" type="button" className="learn-more"> 
            LEARN MORE  
          </a>
          {/*<div class="hero-card">
            <img
              src={images.pitching}
              className="robot-image"
              alt="AI"
            />
          </div>*/}
      </div>
    </div>
  </section>
);

export default AppWrap(Header, 'home');
