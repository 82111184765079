import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Portfolios.scss';

const Portfolio = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [animateCard] = useState({ y: 0, opacity: 1 });
  const [visibleOverlay, setVisibleOverlay] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const query = '*[_type == "portfolios"]';

    client.fetch(query).then((data) => {
      console.log('Portfolios Data:', data); // Log portfolios data
      setPortfolios(data);
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); // Check if screen width is less than or equal to 1024px
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial value

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <h2 className="head-text">My Selected <span>Portfolios</span></h2>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__portfolio-portfolio"
      >
        {portfolios.map((portfolio, index) => (
          <div className="app__portfolio-item app__flex" key={index}>
            <div
              className="app__portfolio-img app__flex"
              onClick={isMobile ? () => setVisibleOverlay(index === visibleOverlay ? null : index) : undefined}
            >
              <img src={portfolio.imgUrl && urlFor(portfolio.imgUrl)} alt={portfolio.title} />
              <a href={portfolio.projectLink} target="_blank" rel="noreferrer">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isMobile ? (visibleOverlay === index ? 1 : 0) : undefined }}
                  whileHover={{ opacity: isMobile ? undefined : [0, 1] }}
                  transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                  className="app__portfolio-hover app__flex"
                >
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </motion.div>
              </a>
            </div>

            <div className="app__portfolio-content app__flex">
              <h4 className="bold-text">{portfolio.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>{portfolio.description}</p>
              <div className='porto__tags-list'>
                {(portfolio.tags || []).map((tag, tagIndex) => (
                  <button key={tagIndex} className="porto__tags">{tag}</button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Portfolio, 'app__portfolios'),
  'portfolios',
  'app__whitebg',
);
