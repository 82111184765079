import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { images } from '../../constants';
import { BsInstagram } from 'react-icons/bs';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>
      <ul className="app__navbar-links">
        {['home', 'about', 'skills', 'portfolios', 'experiences', 'education'].map((item) => (
          <li className="app__flex p-text" key={`link-${item}`}>
            <div />
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      {['contact'].map((item) => (
          
             <a href={`#${item}`} key={`link-${item}`} type="button" className='contact_button' >{item}</a>
        ))}

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'skills', 'portfolios', 'experiences', 'education', 'contact'].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
              <li className='navbar-socialmedia'>
                <a href="https://www.linkedin.com/in/argya-maghfirridho-7a7714163" target="_blank">
                  <FaLinkedinIn/>
                </a>
                <a href="https://instagram.com/argya_maxphiride?igshid=bG51N3Uxdnc4OXdz" target="_blank">
                  <BsInstagram/>
                </a>
                <a href="https://github.com/argyax" target="_blank">
                  <FaGithub/>
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
