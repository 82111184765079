import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './Education.scss';

const Education = () => {
  const [educationData, setEducationData] = useState([]);
  const [trainings, setTrainings] = useState([]);

  useEffect(() => {
    const query = '*[_type == "trainings"]';

    client.fetch(query).then((data) => {
      setTrainings(data);
    });
  }, []);

  useEffect(() => {
    const educationQuery = '*[_type == "education"]';

    client.fetch(educationQuery).then((data) => {
      setEducationData(data);
    });
  }, []);

  return (
    <>
      <section>
        <h2 className="head-text">Education & Training</h2>
        <div className='education__training'>
            <motion.div className="app__education-list">
              {educationData.map((education) => (
                <motion.div
                  key={education.name}
                  whileInView={{ opacity: [0, 1] }}
                  transition={{ duration: 0.5 }}
                  className="app__education-item app__flex-education">
                  <div
                    className="app__flex-education"
                    style={{ backgroundColor: education.bgColor }}
                  >
                    {education.icon && (
                      <img src={urlFor(education.icon).url()} alt={education.name} />
                    )}
                  </div>
                  <p className="major-text">{education.major}</p>
                  <p className="institution-text">{education.institution}</p>
                  <p className="year-text">{education.year}</p>
                </motion.div>
              ))}
            </motion.div>
          
          <div className="app__trainings">
            {trainings.map((trainingItem) => (
              <motion.div
                className="app__trainings-item"
                key={trainingItem.year}>
                <div className="app__trainings-year">
                  <p className="year-text">{trainingItem.year}</p>
                </div>
                <motion.div className="app__trainings-works">
                  {trainingItem.trainings.map((training) => (
                    <>
                      <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                        className="app__trainings-work"
                        data-tip
                        data-for={training.name}
                        key={training.name}
                      >
                        <h4 className="role-text">{training.name}</h4>
                        <p className="company-text">{training.company}</p>
                        <p className="work-desc">{training.desc}</p>
                        <div className='hashtag-list'>
                          {training.tags.map((tag, index) => (
                            <button key={index} className='hashtag'>{tag}</button>
                          ))}
                        </div>
                      </motion.div>
                      <ReactTooltip
                        id={training.name}
                        effect="solid"
                        arrowColor="#fff"
                        className="skills-tooltip"
                      >
                        {training.desc}
                      </ReactTooltip>
                    </>
                  ))}
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AppWrap(
  MotionWrap(Education, 'app__skills'),
  'education',
  'app__primarybg'
);
